(function($){

    const SQHThemeSliders = {

        init: function() {

            if( jQuery('.sqh-slider').length ) {

                // setTimeout(function(){

                    jQuery('.sqh-slider').each(function(i, slider) {

                        let arrows = false;

                        if( jQuery(this).attr('data-arrows') == 'true' ) {
                            arrows = true;
                        }

                        let autoplaySpeed = 5000;

                        if( jQuery(this).attr('data-autoplayspeed') ) {
                            autoplaySpeed = jQuery(this).attr('data-autoplayspeed');
                        }

                        let transitionSpeed = 1000;

                        if( jQuery(this).attr('data-transitionspeed') ) {
                            transitionSpeed = jQuery(this).attr('data-transitionspeed');
                        }

                        let slickArgs = {
                            autoplay: true,
                            autoplaySpeed: autoplaySpeed,
                            arrows: arrows,
                            adaptiveHeight: false,
                            fade: true,
                            speed: transitionSpeed,
                            infinite: true
                        };

                        if( jQuery(this).attr('data-customarrows') == 'true' ) {
                            slickArgs['prevArrow'] = '<button class="slick-prev slick-arrow"></button>';
                            slickArgs['nextArrow'] = '<button class="slick-next slick-arrow"></button>';
                        }

                        let slickSlider = jQuery(this).slick( slickArgs );

                        slickSlider.attr('data-animation', true);
                        slickSlider.attr('data-slideshow', true);

                        slickSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){

                            let animation = slick.$slider.attr('data-animation');

                            if( animation == 'true' ) {

                                slickSlider.find('.sqh-slide-img').removeClass('animated');

                                let nextSlideDom = jQuery(slick.$slides.get(nextSlide));

                                nextSlideDom.find('.sqh-slide-img').addClass('animated');

                            }

                            let slideshow = slick.$slider.attr('data-slideshow');

                            if( slideshow == 'true' ) {
                                slick.slickPlay();
                                slick.slickNext();
                            }

                        });

                    });

                    jQuery('.sqh-slider-secondary').each(function(i, slider){

                        let autoplay = jQuery(this).attr('data-autoplay');

                        let autoplaySpeed = 5000;

                        if( jQuery(this).attr('data-autoplayspeed') ) {
                            autoplaySpeed = jQuery(this).attr('data-autoplayspeed');
                        }

                        let transitionSpeed = 1000;

                        if( jQuery(this).attr('data-transitionspeed') ) {
                            transitionSpeed = jQuery(this).attr('data-transitionspeed');
                        }

                        let slickArgs = {
                            autoplay: autoplay == 'true' ? true : false,
                            autoplaySpeed: autoplaySpeed,
                            arrows: true,
                            prevArrow: '<button class="slick-prev slick-arrow"></button>',
                            nextArrow: '<button class="slick-next slick-arrow"></button>',
                            adaptiveHeight: false,
                            fade: true,
                            speed: transitionSpeed,
                            infinite: true
                        };

                        if( autoplay == 'true' ) {
                            slickArgs['autoplaySpeed'] = 5000;
                        }

                        jQuery(this).slick( slickArgs );

                    });

                    SQHThemeSliders.slidersResize();

                // }, 50);

            }

            if( jQuery('.sqh-get-inspired-v2-slider').length ) {

                jQuery('.sqh-get-inspired-v2-slider').each(function(i, slider) {

                    let direction = jQuery(this).attr('data-dir');
                    let arrows = jQuery(this).attr('data-arrows');

                    let autoplaySpeed = 5000;

                    if( jQuery(this).attr('data-autoplayspeed') ) {
                        autoplaySpeed = jQuery(this).attr('data-autoplayspeed');
                    }

                    let transitionSpeed = 1000;

                    if( jQuery(this).attr('data-transitionspeed') ) {
                        transitionSpeed = jQuery(this).attr('data-transitionspeed');
                    }

                    let slickArgs = {
                        autoplay: true,
                        autoplaySpeed: autoplaySpeed,
                        arrows: arrows == '1' ? true : false,
                        // rtl: direction == 'left' ? false : true,
                        prevArrow: arrows == '1' ? '<button class="slick-prev slick-arrow"></button>' : '',
                        nextArrow: arrows == '1' ? '<button class="slick-next slick-arrow"></button>' : '',
                        adaptiveHeight: false,
                        speed: transitionSpeed,
                        infinite: true,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        // variableWidth: true,
                        cssEase: 'linear',
                        rtl: direction == 'right' ? true : false,
                        responsive: [
                            {
                                breakpoint: 481,
                                settings: {
                                    slidesToShow: 1
                                }
                            },
                            {
                                breakpoint: 769,
                                settings: {
                                    slidesToShow: 2
                                }
                            }
                        ]
                    };

                    jQuery(this).slick( slickArgs );

                });

            }

        },

        slidersResize: function() {

            if( jQuery('.sqh-slider').length ) {

                let windowWidth = jQuery(window).width();
                if( windowWidth <= 1024 ) {

                    let slideWidth  = windowWidth * 0.4;

                    slideWidth = Math.ceil( slideWidth / 10 ) * 10;

                    jQuery('.sqh-slider').each(function(i, slider) {
                        jQuery(this).parent().css('height', slideWidth + 'px');
                        jQuery(this).find('.sqh-slider-slide-container').css('min-height', slideWidth + 'px')
                    });

                }

            }

        },

        onSliderMenuToggle: function( trigger ) {

            let panel = trigger.parents('.sqh-slider-container').find('.sqh-slider-panel').slideToggle();

        },
        onSliderAnimationToggle: function( trigger ) {

            if( trigger.hasClass('stop-animation') ) {

                trigger.removeClass('stop-animation').addClass('start-animation').text( sqhFrontEnd.s.staani );
                trigger.parents('.sqh-slider-container').find('.sqh-slider').attr('data-animation', false);

            } else if( trigger.hasClass('start-animation') ) {

                trigger.removeClass('start-animation').addClass('stop-animation').text( sqhFrontEnd.s.stoani );
                trigger.parents('.sqh-slider-container').find('.sqh-slider').attr('data-animation', true);

            }

            trigger.parents('.sqh-slider-container').find('.sqh-slider-panel').slideToggle();

        },
        onSliderSlideshowStartStop: function( trigger ) {

            if( trigger.hasClass('stop-slideshow') ) {

                trigger.removeClass('stop-slideshow').addClass('start-slideshow').text( sqhFrontEnd.s.stasli );
                trigger.parents('.sqh-slider-container').find('.sqh-slider').attr('data-slideshow', false);

                trigger.parents('.sqh-slider-container').find('.sqh-slider').slick('slickPause');

            } else if( trigger.hasClass('start-slideshow') ) {

                trigger.removeClass('start-slideshow').addClass('stop-slideshow').text( sqhFrontEnd.s.stosli );
                trigger.parents('.sqh-slider-container').find('.sqh-slider').attr('data-slideshow', true);

                trigger.parents('.sqh-slider-container').find('.sqh-slider').slick('slickPlay');

            }

            trigger.parents('.sqh-slider-container').find('.sqh-slider-panel').slideToggle();

        },
        onSliderSlideGoTo: function( slide ) {

            let index = slide.attr('data-index');

            slide.parents('.sqh-slider-container').find('.sqh-slider').slick('slickGoTo', index);
            slide.parent().slideToggle();

        },

    }

    jQuery(document).ready(function() {

        SQHThemeSliders.init();

        jQuery('.open-slider-menu, .sqh-slide-img').on('click', function(){
            SQHThemeSliders.onSliderMenuToggle( jQuery(this) );
        });

        jQuery('body').on('click', '.start-animation, .stop-animation', function() {
            SQHThemeSliders.onSliderAnimationToggle( jQuery(this) );
        });

        jQuery('body').on('click', '.start-slideshow, .stop-slideshow', function() {
            SQHThemeSliders.onSliderSlideshowStartStop( jQuery(this) );
        });

        jQuery('body').on('click', '.sqh-slider-panel-item', function() {
            SQHThemeSliders.onSliderSlideGoTo( jQuery(this) );
        });

    });

    jQuery(window).on('resize', function() {
        SQHThemeSliders.slidersResize();
    });

})(jQuery)
