export default class SQHThemeAccordions {

    onAccordionExpandToggle( trigger ) {

        trigger.parent().find('.acc-content').slideToggle();
        trigger.parent().find('.acc-expand').toggleClass('expand');

    }

}
