export default class SQHThemeTrustYou {

    constructor() {
        this.state = {
            blockArgs: {
                message: ''
            }
        }
    }

    loadHotelGuestSatisfaction( gsCont ) {

        let hotelId = gsCont.attr('data-hotelid');

        if( hotelId ) {

            jQuery.ajax('/wp-json/sqh-theme/v1/gthgf', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-WP-Nonce', sqhFrontEnd.nonce)
                },
                data: JSON.stringify({
                    hotelId: hotelId,
                    page: 'single',
                    lang: sqhFrontEnd.clang
                }),
                dataType: 'json',
                success: function( singleHotelTrustYou ) {

                    // console.log(singleHotelTrustYou);
                    
                    if( singleHotelTrustYou.markup.guest_satisfaction ) {
                        jQuery('.sqh-hotel-guest-satisfaction-left.is-loading').html( singleHotelTrustYou.markup.guest_satisfaction ).removeClass('is-loading');
                    }
                    
                    if( singleHotelTrustYou.markup.reviews.left ) {
                        jQuery('#sqh-hotel-reviews .sqh-hotel-reviews-left .sqh-hotel-reviews-bars').html( singleHotelTrustYou.markup.reviews.left );
                    }
                    
                    if( singleHotelTrustYou.markup.reviews.right ) {
                        jQuery('#sqh-hotel-reviews .sqh-hotel-reviews-right .sqh-hotel-reviews-cards').html( singleHotelTrustYou.markup.reviews.right );
                    }

                    
                    setTimeout(function(){
                        // console.log(jQuery('.trustyou-hotel-listing-reviews-body[data-hotelid="' + hotelId + '"]'));
                        if( jQuery('.trustyou-hotel-listing-reviews-body[data-hotelid="' + hotelId + '"]').length && singleHotelTrustYou.markup_modal ) {
                            jQuery('.trustyou-hotel-listing-reviews-body[data-hotelid="' + hotelId + '"]').html( singleHotelTrustYou.markup_modal );
                        }
                        // console.log(jQuery('.trustyou-hotel-listing-reviews-body[data-hotelid="' + hotelId + '"]'), hotelId);
                    }, 1000);

                },
                error: function( xhr, errorStatus, error ) {
                    // hotel.unblock();
                }
            });

        }

    }

    getTrustYouHotelListingScore() {

        let self = this;

        jQuery('.trustyou-score-hotel-listing').each(function(i, hotelListing) {

            let hotel   = jQuery(this);
            let hotelId = jQuery(this).attr('data-hotelid');
            let loaded  = jQuery(this).attr('data-trustyouloaded');

            if( hotelId && ! loaded ) {

                hotel.css('visibility', 'hidden');

                hotel.block( self.state.blockArgs );

                jQuery.ajax('/wp-json/sqh-theme/v1/gthgf', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('X-WP-Nonce', sqhFrontEnd.nonce)
                    },
                    data: JSON.stringify({
                        hotelId: hotelId,
                        page: 'overview',
                        lang: sqhFrontEnd.clang
                    }),
                    dataType: 'json',
                    success: function( trustyou ) {

                        if( trustyou && trustyou.seal && trustyou.seal.response ) {

                            let response = trustyou.seal.response;

                            if( response.score_description ) {
                                hotel.find('.trustScoreRating span').text( response.score_description );
                            }
                            if( response.score ) {
                                hotel.find('.trustScoreGaugeBar').css('width', response.score + 'px');
                                hotel.find('.trustScoreGaugeValue').text( response.score + '%' );
                            }
                            if( response.reviews_count ) {
                                hotel.find('.trustScoreReview span').text( response.reviews_count );
                            }

                            hotel.attr('data-trustyouloaded', 1);
                            hotel.css('visibility', 'visible');

                        }

                        if( trustyou && trustyou.markup ) {
                            hotel.parents('.sqh-hwf-hotel-center').find('.trustyou-hotel-listing-reviews .trustyou-hotel-listing-reviews-body').html( trustyou.markup );
                            hotel.next('.trustyou-hotel-listing-reviews').find('.trustyou-hotel-listing-reviews-body').html( trustyou.markup );
                        }

                        hotel.unblock();

                    },
                    error: function( xhr, errorStatus, error ) {
                        hotel.unblock();
                    }
                });

            }

        });

    }

}
