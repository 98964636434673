/* eslint-disable @wordpress/dependency-group */
// import $ from 'jquery';

import './inc/modals';
import './inc/responsive-menus';
import './inc/sliders';
import SQHThemeAccordions from './inc/accordions';
import SQHThemeTrustYou from './inc/trustyou';
import SQHThemeHotelsFilters from './inc/hotels-filters';
import SQHThemeHotels from './inc/hotels';
import SQHThemeUtils from './inc/utils';
import 'slick-carousel-latest';
import 'jquery-blockui';
import './inc/async-content';

(function($){

    let hotels        = new SQHThemeHotels();
    let hotelsFilters = new SQHThemeHotelsFilters();
    let trustYou      = new SQHThemeTrustYou();
    let accordions    = new SQHThemeAccordions();
    let utils         = new SQHThemeUtils();

    const SQHTheme = {

        state: {
            blockArgs: {
                message: ''
            }
        },
        init: function() {

            // if( jQuery('body').hasClass('single-sqh_hotels') && sqhFrontEndUrl && sqhFrontEndUrl.url ) {
            //     window.history.pushState('', '', sqhFrontEndUrl.url);
            // }

            if( jQuery('.sqh-hwf-filters-preload.load-find-hotels-filters').length || jQuery('.sqh-hwf-filters-preload.load-find-meeting_rooms-filters').length || jQuery('.sqh-hwf-filters-preload.load-find-meetings-filters').length ) {

                if( jQuery('.sqh-hwf-filters-preload.load-find-hotels-filters').length ) {

                    jQuery('.sqh-hwf-filters-preload.load-find-hotels-filters').each(function(i, filters){
                        hotelsFilters.loadFindHotelsFilters( jQuery(this) );
                    });

                }

                if( jQuery('.sqh-hwf-filters-preload.load-find-meeting_rooms-filters').length ) {

                    jQuery('.sqh-hwf-filters-preload.load-find-meeting_rooms-filters').each(function(i, filters){
                        hotelsFilters.loadFindMeetingsFilters( jQuery(this) );
                    });

                }

                if( jQuery('.sqh-hwf-filters-preload.load-find-meetings-filters').length ) {

                    jQuery('.sqh-hwf-filters-preload.load-find-meetings-filters').each(function(i, filters){
                        hotelsFilters.loadFindMeetingsFilters( jQuery(this) );
                    });

                }

            }

            if( jQuery('.tooltip').length ) {
                jQuery('.tooltip').each(function(){
                    jQuery(this).tooltip({
                        position: {
                            my: 'left top+5'
                        }
                    });
                });
            }

            if( jQuery('.segmentation-tooltip').length ) {

                jQuery(document).tooltip({
                    items: '.segmentation-tooltip, .tt-tooltip',
                    tooltipClass: 'segmentation-tt',
                    content: function() {

                        let element = jQuery(this);
                        let title   = element.parent().find('.sqh-tooltip .sqh-tooltip-title').html();
                        let desc    = element.parent().find('.sqh-tooltip .sqh-tooltip-desc').html();

                        let html = '';
                        if( title ) {
                            html += title + '<br/><br/>';
                        }
                        if( desc ) {
                            html += desc;
                        }

                        return html;

                    }
                });

            }

            // if( jQuery('.trustyou-score-hotel-listing').length ) {
                // trustYou.getTrustYouHotelListingScore();
            // }

            if( jQuery('.sqh-hotel-guest-satisfaction.is-loading').length || jQuery('.sqh-hotel-guest-satisfaction .is-loading').length ) {

                if( jQuery('.sqh-hotel-guest-satisfaction.is-loading').length ) {
                    jQuery('.sqh-hotel-guest-satisfaction.is-loading').each(function(i, gsLoad){
                        trustYou.loadHotelGuestSatisfaction( jQuery(this) );
                    });
                }

                if( jQuery('.sqh-hotel-guest-satisfaction .is-loading').length ) {
                    jQuery('.sqh-hotel-guest-satisfaction .is-loading').each(function(i, gsLoad){
                        trustYou.loadHotelGuestSatisfaction( jQuery(this) );
                    });
                }

            } else {
                // jQuery('#sqh-hotel-reviews').hide();
            }

        }

    }

    jQuery(document).ready(function() {

        SQHTheme.init();

        jQuery(document).on('click', 'a[href^="#"]', function(e) {
            e.preventDefault();
            utils.smoothScrollToAnchor( jQuery(this) );
        });

        jQuery(document).on('click', '#sqh-go-to-top', function(e){
            e.preventDefault();
            utils.goToTop();
        });

        jQuery('.sqh-nav-list a').on('click', function(e){
            e.preventDefault();
            utils.showCaseNavClick( jQuery(this) );
        });

        jQuery('.sqh-social-bar a img').on('mouseenter', function(e){
            utils.footerSocialBarLinkMouseEnterLeave( jQuery(this), 'replace' );
        });

        jQuery('.sqh-social-bar a img').on('mouseleave', function(e){
            utils.footerSocialBarLinkMouseEnterLeave( jQuery(this), 'revert' );
        });

        jQuery('body').on('click', '.sqh-hwf-filter-label', function(e){
            hotelsFilters.onFilterLabelClick( jQuery(this) );
        });

        jQuery('body').on('click', '.sqh-hwf-filter', function(e){
            hotelsFilters.onFilterClick( jQuery(this) );
        });

        jQuery('#filters-new-search').on('click', function(e){
            hotelsFilters.onFilterReset( jQuery(this) );
        });

        jQuery('#filters-find-hotels-free-search').on('focus keyup', function(){
            hotelsFilters.onHotelSearch( jQuery(this) );
            // hotelsFilters.findHotelApplyFilters( jQuery(this) );
            // hotelsFilters.onFilterClick( jQuery(this) );
        });

        jQuery('body').on('click', '.sqh-hwf-hotel-header', function(e){
            hotels.onHotelHeaderClick( jQuery(this) );
        });

        jQuery('#sqh-load-more-hotels').on('click', function(){
            hotels.loadMoreHotels( jQuery(this) );
        });

        jQuery('body').on('click', '.sqh-accordion .acc-expand, .sqh-accordion .acc-title', function(){
            accordions.onAccordionExpandToggle( jQuery(this) );
        });

    });

    jQuery(window).scroll(function() {

        let scroll = jQuery(window).scrollTop();

        let isSticky = sqhFrontEnd.sticky;
        isSticky = isSticky ? parseInt( isSticky ) : 0;

        if (scroll >= 1 && isSticky) {
            jQuery('header.site-header').addClass('sticky');
        } else {
            jQuery('header.site-header').removeClass('sticky');
        }

    });

})(jQuery);
