import Cookies from 'js-cookie';

(function($){

    const SQHThemeModals = {

        state: {
            mapModals: {
                googleMaps: {
                    text: sqhFrontEnd.s.mogmap,
                    moreInfo: sqhFrontEnd.s.mogmai,
                    restLink: 'ggmm',
                    privacyLink: 'https://policies.google.com/technologies/cookies'
                },
                bingMaps: {
                    text: sqhFrontEnd.s.mobima,
                    moreInfo: sqhFrontEnd.s.mobimo,
                    restLink: 'gbim',
                    privacyLink: 'https://privacy.microsoft.com/en-us/privacystatement#maincookiessimilartechnologiesmodule'
                }
            }
        },
        onModalCreate: function( content ) {
            jQuery('body').append('<div class="sqh-modal"><div class="sqh-modal-inner"></div></div>');
        },
        onModalDestroy: function() {
            jQuery('.sqh-modal').remove();
        },
        onOpenInModal: function( trigger ) {

            let container = trigger.attr('data-container');

            this.onModalCreate();

            // html += '';

            jQuery('.sqh-modal-inner').append( '<div class="sqh-modal-small">' + jQuery(container).html() + '</div>' );

        },
        onLeavePromptClick: function( trigger ) {

            let hasCookie = Cookies.get( 'leavePrompt' );

            if( ! parseInt( hasCookie ) ) {

                this.onModalDestroy();
                this.onModalCreate();

                let html = '<div class="leave-prompt-modal">';
                    html += '<div class="leave-prompt-text">';
                        html += '<span>' + sqhFrontEnd.s.leasit + '</span>';
                    html += '</div>';
                    html += '<div class="leave-prompt-buttons">';
                        html += '<a href="' + trigger.attr('href') + '" class="sqh-button-link" data-answer="3">' + sqhFrontEnd.s.yesstr + '</a>';
                        html += '<a href="' + trigger.attr('href') + '" class="sqh-button-link" data-answer="0">' + sqhFrontEnd.s.nostri + '</a>';
                    html += '</div>';
                    html += '<div class="leave-prompt-ask-me">';
                        html += '<input id="leave-prompt-dont-ask" type="checkbox">';
                        html += '<label for="leave-prompt-dont-ask">' + sqhFrontEnd.s.donoas + '</label>';
                    html += '</div>';
                html += '</div>';

                jQuery('.sqh-modal').append( html );

            } else {

                window.open( trigger.attr('href') );

            }

        },
        onLeavePromptButtonClick: function( trigger ) {

            let href = trigger.attr('href');

            switch( trigger.attr('data-answer') ) {
                case '11':
                    this.onMapModalCreate( trigger );
                break;
                case '1':
                    //Panorama modal
                    this.onPanoramaModalCreate( href );
                break;
                case '3':
                    this.onModalDestroy();
                    window.open( href );
                break;
                case '0':
                    this.onModalDestroy();
                break;
            }

        },
        onMapModal: function( trigger, type ) {

            let hasCookie = Cookies.get( 'leavePrompt' );

            if( ! parseInt( hasCookie ) ) {

                this.onModalDestroy();
                this.onModalCreate();

                let html = '<div class="leave-prompt-modal">';
                    html += '<div class="leave-prompt-text">';
                        html += '<span>' + this.state.mapModals[type].text + '</span>';
                    html += '</div>';
                    html += '<div class="leave-prompt-buttons">';
                        html += '<a href="' + this.state.mapModals[type].privacyLink + '" class="leave-prompt" style="margin-right: 10px;">' + this.state.mapModals[type].moreInfo + '</span>';
                        html += '<a data-lat="' + trigger.attr('data-lat') + '" data-lon="' + trigger.attr('data-lon') + '" data-address="' + trigger.attr('data-address') + '" data-name="' + trigger.attr('data-name') + '" class="sqh-button-link" data-answer="11" data-type="' + type + '">' + sqhFrontEnd.s.yesstr + '</a>';
                        html += '<a class="sqh-button-link" data-answer="0">' + sqhFrontEnd.s.nostri + '</a>';
                    html += '</div>';
                    html += '<div class="leave-prompt-ask-me">';
                        html += '<input id="leave-prompt-dont-ask" type="checkbox">';
                        html += '<label for="leave-prompt-dont-ask">' + sqhFrontEnd.s.donoas + '</label>';
                    html += '</div>';
                html += '</div>';

                jQuery('.sqh-modal').append( html );

            } else {

                //Open modal

                this.onMapModalCreate( trigger.attr('href'), type );

            }

        },
        onMapModalCreate: function( trigger ) {

            this.onModalDestroy();
            this.onModalCreate();

            let type = trigger.attr('data-type');

            let html = '<div class="sqh-modal gmaps-modal">';
                html += '<div class="close-modal"><span class="dashicons dashicons-no"></span></div>';
                html += '<div class="sqh-preloader-center"><div class="lds-dual-ring load-gmaps"></div></div>';
            html += '</div>';

            jQuery('.sqh-modal').append( html );

            jQuery.ajax('/wp-json/sqh-theme/v1/' + this.state.mapModals[type].restLink, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-WP-Nonce', sqhFrontEnd.nonce)
                },
                data: JSON.stringify({
                    lat: trigger.attr('data-lat'),
                    lon: trigger.attr('data-lon'),
                    address: trigger.attr('data-address'),
                    name: trigger.attr('data-name')
                }),
                dataType: 'json',
                success: function( gmap ) {
                    jQuery('.sqh-preloader-center').replaceWith( gmap.gmapsData );
                },
                error: function( xhr, errorStatus, error ) {
                    // trigger.remove();
                }
            });

        },
        onPanoramaModal: function( trigger ) {

            let hasCookie = Cookies.get( 'leavePrompt' );

            if( ! parseInt( hasCookie ) ) {

                this.onModalDestroy();
                this.onModalCreate();

                let html = '<div class="leave-prompt-modal">';
                    html += '<div class="leave-prompt-text">';
                        html += '<span>' + sqhFrontEnd.s.mopico + '</span>';
                    html += '</div>';
                    html += '<div class="leave-prompt-buttons">';
                        html += '<a href="https://www.moving-pictures.de/datenschutz.html?lg=e" class="leave-prompt" style="margin-right: 10px;">' + sqhFrontEnd.s.morinf + '</span>';
                        html += '<a href="' + trigger.attr('href') + '" class="sqh-button-link" data-answer="1">' + sqhFrontEnd.s.yesstr + '</a>';
                        html += '<a href="' + trigger.attr('href') + '" class="sqh-button-link" data-answer="0">' + sqhFrontEnd.s.nostri + '</a>';
                    html += '</div>';
                    html += '<div class="leave-prompt-ask-me">';
                        html += '<input id="leave-prompt-dont-ask" type="checkbox">';
                        html += '<label for="leave-prompt-dont-ask">' + sqhFrontEnd.s.donoas + '</label>';
                    html += '</div>';
                html += '</div>';

                jQuery('.sqh-modal').append( html );

            } else {

                //Open modal

                this.onPanoramaModalCreate( trigger.attr('href') );

            }

        },
        onPanoramaModalCreate: function( link ) {

            this.onModalDestroy();
            this.onModalCreate();

            let html = '';
                html += '<div class="close-modal"><span class="dashicons dashicons-no"></span></div>';
                html += '<iframe src="' + link + '" class="panorama-iframe" scrolling="no" marginheight="0" marginwidth="0" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" frameborder="no"></iframe>';
            html += '';

            jQuery('.sqh-modal').append( html );

        },
        onLeavePromptChange: function( trigger ) {

            let value = trigger.is(':checked') ? 1 : 0;

            if( value ) {
                Cookies.set( 'leavePrompt', 1, {expires: 7, path: ''} );
            } else {
                Cookies.set( 'leavePrompt', 0, {expires: 7, path: ''} );
            }

        },
        onHotelOverviewSlider: function( trigger ) {

            this.onModalCreate();

            jQuery('.sqh-modal-inner').after('<div class="sqh-hwf-filters-preload load-filters" style="position: relative; top: 50%; left: 50%;"><div class="lds-dual-ring"></div></div>');

            let hotelId = trigger.attr('data-hotelid');

            jQuery.ajax('/wp-json/sqh-theme/v1/ghs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-WP-Nonce', sqhFrontEnd.nonce)
                },
                data: JSON.stringify({
                    hotelId: hotelId
                }),
                dataType: 'json',
                success: function( hotelSlider ) {
                    if( hotelSlider.slider ) {
                        jQuery('.sqh-hwf-filters-preload.load-filters').replaceWith( '<div class="close-modal popup-slider-close"><span class="dashicons dashicons-no"></span></div>' + hotelSlider.slider );
                        jQuery('.sqh-slider-popup').slick({
                            prevArrow: '<button class="slick-prev slick-arrow"></button>',
                            nextArrow: '<button class="slick-next slick-arrow"></button>',
                        });
                    }
                    // jQuery('.sqh-preloader-center').replaceWith( gmap.gmapsData );
                },
                error: function( xhr, errorStatus, error ) {
                    // trigger.remove();
                }
            });

        }

    }

    jQuery(document).ready(function() {

        jQuery('body').on('click', '.leave-prompt', function(e){
            e.preventDefault();
            SQHThemeModals.onLeavePromptClick( jQuery(this) );
        });

        jQuery('body').on('click', '.leave-prompt-modal .sqh-button-link', function(e) {
            e.preventDefault();
            SQHThemeModals.onLeavePromptButtonClick( jQuery(this) );
        });

        jQuery('body').on('click', '.sqh-modal-inner', function() {
            SQHThemeModals.onModalDestroy();
        });

        jQuery('body').on('click', '.panorama-confirm', function(e) {
            e.preventDefault();
            SQHThemeModals.onPanoramaModal( jQuery(this) );
        });

        jQuery('body').on('click', '.gmaps-confirm', function(e) {
            e.preventDefault();
            SQHThemeModals.onMapModal( jQuery(this), 'googleMaps' );
        });

        jQuery('body').on('click', '.bing-confirm', function(e) {
            e.preventDefault();
            SQHThemeModals.onMapModal( jQuery(this), 'bingMaps' );
        });

        jQuery('body').on('change', '#leave-prompt-dont-ask', function(){
            SQHThemeModals.onLeavePromptChange( jQuery(this) );
        });

        jQuery('body').on('click', '.close-modal', function(){
            SQHThemeModals.onModalDestroy();
        });

        jQuery('body').on('click', '.open-in-modal', function(){
            SQHThemeModals.onOpenInModal( jQuery(this) );
        });

        jQuery('body').on('click', '.sqh-hwf-hotel-img', function(e) {
            e.preventDefault();
            SQHThemeModals.onHotelOverviewSlider( jQuery(this) );
        });

    });

})(jQuery);
