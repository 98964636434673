export default class SQHThemeUtils {

    smoothScrollToAnchor( trigger ) {

        jQuery('html, body').animate({
            scrollTop: jQuery( trigger.attr('href') ).offset().top
        }, 1000);

    }

    goToTop() {
        jQuery('html,body').animate({
            scrollTop: jQuery('body').offset().top
        }, 1500);
    }

    showCaseNavClick( trigger ) {

        let index = trigger.attr('data-index');

        trigger.parents('.sqh-hotels-showcase').find('.sqh-nav-list a').removeClass('active');
        trigger.parents('.sqh-hotels-showcase').find('.sqh-nav-list a[data-index="' + index + '"]').addClass('active');
        trigger.parents('.sqh-hotels-showcase').find('.sqh-showcase-item').hide();
        trigger.parents('.sqh-hotels-showcase').find('.sqh-showcase-item[data-index="' + index + '"]').show();

    }

    footerSocialBarLinkMouseEnterLeave( trigger, action ) {

        let srcValue = trigger.attr('src');
        let replace = trigger.attr('data-replace');
        let replaceWith = trigger.attr('data-replacewith');

        switch( action ) {

            case 'replace':

                if( replace && replaceWith ) {
                    trigger.attr( 'src', srcValue.replace( replace, replaceWith ) );
                }

            break;
            case 'revert':

                if( replace && replaceWith ) {
                    trigger.attr( 'src', srcValue.replace( replaceWith, replace ) );
                }

            break;

        }

    }

}
