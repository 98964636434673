import SQHThemeTrustYou from './trustyou';

export default class SQHThemeHotels {

    constructor() {
        this.trustYou = new SQHThemeTrustYou;
    }

    onHotelHeaderClick( trigger ) {

        let url = trigger.attr('data-url');
        if( url ) {
            window.location.href = url;
        }

    }

    loadMoreHotels( trigger ) {

        let self    = this;
        let exclude = [];
        // let type    = 'hotels';
        let type = trigger.attr('data-filterstype');

        type = type ? type : 'hotels';

        // let idx = 0;
        if( jQuery('.sqh-hwf-hotel-container.inactive').length ) {
            jQuery('.sqh-hwf-hotel-container.inactive').each(function(i, hotel){
                jQuery(this).removeClass('inactive').addClass('active');
                if( i == 9 ) {
                    if( ! jQuery('.sqh-hwf-hotel-container.inactive').length ) {
                        trigger.remove();
                    }
                    return false;
                }
            });
        } else {
            trigger.remove();
        }

        // if( jQuery('body').hasClass('page-template-template-hotels-mr') ) {
        //     type = 'meeting_rooms';
        // }

        // jQuery('.sqh-hwf-hotel').each(function(i, hotel) {

        //     let hotelId = jQuery(this).attr('data-hotelid');

        //     if( hotelId && ! exclude.includes( hotelId ) ) {
        //         exclude.push( hotelId );
        //     }

        // });

        // jQuery.ajax('/wp-json/sqh-theme/v1/lmh', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     beforeSend: function (xhr) {
        //         xhr.setRequestHeader('X-WP-Nonce', sqhFrontEnd.nonce)
        //     },
        //     data: JSON.stringify({
        //         exclude: exclude,
        //         type: type,
        //         lang: sqhFrontEnd.clang
        //     }),
        //     dataType: 'json',
        //     success: function( hotels ) {

        //         if( hotels.hotelsMarkup ) {
        //             trigger.before( hotels.hotelsMarkup );
        //         }
        //         if( hotels.lastLoad < 10 ) {
        //             trigger.hide();
        //         }

        //         self.trustYou.getTrustYouHotelListingScore();

        //     },
        //     error: function( xhr, errorStatus, error ) {
        //     }
        // });

    }

}
