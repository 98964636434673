import SQHThemeTrustYou from './trustyou';

export default class SQHThemeHotelsFilters {

    constructor() {
        this.trustYou = new SQHThemeTrustYou;
        this.locale   = sqhFrontEnd.wlangs ? sqhFrontEnd.wlangs[ sqhFrontEnd.clang ]['default_locale'] : 'de_DE';
    }

    loadFindHotelsFilters( trigger ) {

        let filtersType       = trigger.attr('data-filterstype');
        let activeFiltersType = trigger.attr('data-activefilterstype');
        let activeFilter      = trigger.attr('data-activefilter');

        jQuery.ajax('/wp-json/sqh-theme/v1/gfhf', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', sqhFrontEnd.nonce)
            },
            data: JSON.stringify({
                lang: sqhFrontEnd.clang,
                locale: this.locale,
                filtersType: filtersType,
                activeFiltersType: activeFiltersType,
                activeFilter: activeFilter
            }),
            dataType: 'json',
            success: function( filters ) {
                if( filters.filtersMarkup ) {
                    trigger.replaceWith( filters.filtersMarkup );
                    if( activeFiltersType && activeFilter ) {
                        jQuery('.sqh-hwf-filters').attr({ 'data-activefilterstype': activeFiltersType, 'data-activefilter': activeFilter});
                        // setTimeout(function(){
                        jQuery('#sqh-hwf-filters-' + activeFiltersType + ' .sqh-hwf-filter-label' ).trigger('click');
                        jQuery('#sqh-hwf-filters-' + activeFiltersType + ' .sqh-hwf-filter-options .sqh-hwf-filter[data-value="' + activeFilter + '"]').trigger('click');
                        // }, 500);
                    }

                }
            },
            error: function( xhr, errorStatus, error ) {
                trigger.remove();
            }
        });

    }

    loadFindMeetingsFilters( trigger ) {

        let filtersType = trigger.attr('data-filterstype');

        jQuery.ajax('/wp-json/sqh-theme/v1/gfmf', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', sqhFrontEnd.nonce)
            },
            data: JSON.stringify({
                locale: this.locale,
                filtersType: filtersType
            }),
            dataType: 'json',
            success: function( filters ) {
                if( filters.filtersMarkup ) {
                    trigger.replaceWith( filters.filtersMarkup );
                }
            },
            error: function( xhr, errorStatus, error ) {
                trigger.remove();
            }
        });

    }

    onFilterLabelClick( trigger ) {

        if( ! trigger.hasClass('active') ) {

            jQuery('.sqh-hwf-filters-rows-container .sqh-hwf-filter-label.active').removeClass('active');
            jQuery('.sqh-hwf-filters-rows-container .sqh-hwf-filter-options.active').slideUp().removeClass('active');

        }

        trigger.parents('.sqh-hwf-filters-row').find('.sqh-hwf-filter-options').slideToggle().toggleClass('active');
        trigger.toggleClass('active');

    }

    onFilterClick( trigger ) {

        let self = this;

        trigger.toggleClass('active');

        if( ! window.loadedHotels ) {

            jQuery('.sqh-hwf-hotels').html('<div class="lds-dual-ring"></div>');

            let type = 'hotels';

            if( jQuery('body').hasClass('page-template-template-hotels-mr') ) {
                type = 'meeting_rooms';
            }

            jQuery('#sqh-load-more-hotels').hide();

            this.loadAllHotels( type, trigger );

        } else {

            switch( trigger.attr('data-filtertype') ) {
                case 'find_hotel':
                    this.findHotelApplyFilters( trigger );
                break;
                case 'meeting_rooms':
                    this.meetingRoomsApplyFilters();
                break;
            }

        }

    }

    onHotelSearch( trigger ) {

        let type = trigger.attr('data-filterstype');

        // if( ! window.loadedHotels ) {
        //     trigger.parent().block({message: ''});
        //     trigger.attr( 'disabled', 'disabled' );
        //     this.loadAllHotels( type, trigger );
        // } else {
            this.findHotelApplyFilters( trigger );
        // }

    }

    loadAllHotels( type, trigger ) {

        let self = this;

        jQuery.ajax('/wp-json/sqh-theme/v1/lah', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', sqhFrontEnd.nonce)
            },
            data: JSON.stringify({
                type: type,
                lang: sqhFrontEnd.clang,
                locale: this.locale
            }),
            dataType: 'json',
            success: function( hotels ) {

                jQuery('#filters-find-hotels-free-search').removeAttr('disabled');
                jQuery('#filters-find-hotels-free-search').parent().unblock();

                if( hotels.hotelsMarkup ) {

                    jQuery('.sqh-hwf-hotels').html( hotels.hotelsMarkup );

                    self.trustYou.getTrustYouHotelListingScore();

                    window.loadedHotels = 1;

                    switch( trigger.attr('data-filtertype') ) {
                        case 'find_hotel':
                            self.findHotelApplyFilters( trigger );
                        break;
                        case 'meeting_rooms':
                            self.meetingRoomsApplyFilters();
                        break;
                    }

                }

            },
            error: function( xhr, errorStatus, error ) {
            }
        });

    }

    onFilterReset() {

        jQuery('.sqh-hwf-hotel').show();

        jQuery('.sqh-hwf-filter').removeClass(['visible', 'invisible', 'active']);
        jQuery('.sqh-hwf-filter-label').removeClass('active');
        jQuery('.sqh-hwf-filter span.filtered').remove();
        jQuery('.sqh-hwf-filter span.default').show();
        jQuery('.sqh-hwf-filter-options.active').slideToggle().removeClass('active');
        jQuery('#filters-free-search, #filters-find-hotels-free-search').val('');

    }

    findHotelApplyFilters( trigger ) {

        let visibleHotels = [];

        jQuery('.sqh-hwf-filter').removeClass(['visible', 'invisible']).show();
        jQuery('.sqh-hwf-filter span.filtered').remove();
        jQuery('#sqh-search-no-res').remove();
        jQuery('.sqh-hwf-filter span.default').show();

        let freeSearch              = jQuery('#filters-find-hotels-free-search').val();
        let filtersRegions          = jQuery('#sqh-hwf-filters-regions').find('.sqh-hwf-filter.active');
        let filtersCities           = jQuery('#sqh-hwf-filters-cities').find('.sqh-hwf-filter.active');
        let filtersClassifications  = jQuery('#sqh-hwf-filters-classifications').find('.sqh-hwf-filter.active');
        let filtersTravelThemes     = jQuery('#sqh-hwf-filters-travel_themes').find('.sqh-hwf-filter.active');
        let filtersTrustYouScore    = jQuery('#sqh-hwf-filters-trustyou_score').find('.sqh-hwf-filter.active');
        let filtersStars            = jQuery('#sqh-hwf-filters-stars').find('.sqh-hwf-filter.active');

        if(
            ! freeSearch &&
            ! filtersRegions.length &&
            ! filtersCities.length &&
            ! filtersClassifications.length &&
            ! filtersTravelThemes.length &&
            ! filtersTrustYouScore.length &&
            ! filtersStars.length
        ) {

            jQuery('.sqh-hwf-hotel').removeClass('active').show();
            jQuery('.sqh-hotel-counter span').text( jQuery('.sqh-hwf-hotel').length );
            visibleHotels = [];

        } else {

            jQuery('.sqh-hwf-hotel').removeClass('active').hide();

            let actFilReg = [];
            let actFilCit = [];
            let actFilCla = [];
            let actFilTrt = [];
            let actFilTru = [];
            let actFilSta = [];
            let filteredHotels = [];

            if( filtersRegions.length ) {
                filtersRegions.each(function(i, afr) {
                    let value = jQuery(this).data('value');
                    if( ! actFilReg.includes( value ) ) {
                        actFilReg.push( value );
                    }
                });
            }

            if( filtersCities.length ) {
                filtersCities.each(function(i, afc) {
                    let value = jQuery(this).data('value');
                    if( ! actFilCit.includes( value ) ) {
                        actFilCit.push( value );
                    }
                });
            }

            if( filtersClassifications.length ) {
                filtersClassifications.each(function(i, afc) {
                    let value = jQuery(this).data('value');
                    if( ! actFilCla.includes( value ) ) {
                        actFilCla.push( value );
                    }
                });
            }

            if( filtersTravelThemes.length ) {
                filtersTravelThemes.each(function(i, afc) {
                    let value = jQuery(this).data('value');
                    if( ! actFilTrt.includes( value ) ) {
                        actFilTrt.push( value );
                    }
                });
            }

            if( filtersTrustYouScore.length ) {
                filtersTrustYouScore.each(function(i, afc){
                    let value = jQuery(this).data('value');
                    if( ! actFilTru.includes( value ) ) {
                        actFilTru.push( value );
                    }
                });
            }

            if( filtersStars.length ) {
                filtersStars.each(function(i, afc){
                    let value = jQuery(this).data('value');
                    if( ! actFilSta.includes( value ) ) {
                        actFilSta.push( value );
                    }
                });
            }

            jQuery('.sqh-hwf-hotel').each(function(i, hotel) {

                let title    = jQuery(this).find('.sqh-hwf-hotel-name').text();
                let location = jQuery(this).find('.sqh-hwf-hotel-cities').text();
                let hotelReg = jQuery(this).attr('data-regions') ? jQuery(this).attr('data-regions').split('|') : [];
                let hotelCit = jQuery(this).attr('data-cities') ? jQuery(this).attr('data-cities').split('|') : [];
                let hotelCla = jQuery(this).attr('data-classifications') ? jQuery(this).attr('data-classifications').split('|') : [];
                let hotelTrt = jQuery(this).attr('data-travel_themes') ? jQuery(this).attr('data-travel_themes').split('|') : [];
                let hotelTru = jQuery(this).attr('data-trustyou_score') ? jQuery(this).attr('data-trustyou_score').split('|') : [];
                let hotelSta = jQuery(this).attr('data-stars') ? jQuery(this).attr('data-stars').split('|') : [];

                if(
                    ( actFilReg.length == 0 || ( actFilReg.length && hotelReg.some(i => actFilReg.includes(i)) ) ) &&
                    ( actFilCit.length == 0 || ( actFilCit.length && hotelCit.some(i => actFilCit.includes(i)) ) ) &&
                    ( actFilCla.length == 0 || ( actFilCla.length && hotelCla.some(i => actFilCla.includes(i)) ) ) &&
                    ( actFilTrt.length == 0 || ( actFilTrt.length && hotelTrt.some(i => actFilTrt.includes(i)) ) ) &&
                    ( actFilTru.length == 0 || ( actFilTru.length && hotelTru.some(i => actFilTru.includes(i)) ) ) &&
                    ( actFilSta.length == 0 || ( actFilSta.length && hotelSta.some(i => actFilSta.includes(i)) ) ) &&
                    ( ! freeSearch || ( freeSearch && ( title.includes( freeSearch ) || title.toLowerCase().includes( freeSearch ) || location.includes( freeSearch ) ||  location.toLowerCase().includes( freeSearch ) ) ) )
                ) {
                    filteredHotels.push( jQuery(this) );
                }

            });

            if( filteredHotels.length ) {

                filteredHotels.map(function(hotel, i){
                    hotel.addClass('active').show();
                    hotel.parent().removeClass('inactive').addClass('active');
                    visibleHotels.push(hotel);
                });

                jQuery('.sqh-hotel-counter span').text( filteredHotels.length );

            } else {

                jQuery('.sqh-hwf-hotels').append('<p id="sqh-search-no-res">' + sqhFrontEnd.s.nohotf + '</p>');

            }

            if( sqhFrontEnd.ftype == 'dependent' ) {

                let activeFilters = {
                    regions: [],
                    cities: [],
                    classifications: [],
                    trustyou_score: [],
                    stars: []
                };

                visibleHotels.map(function(hotel, i) {

                    // let atts = [ 'regions', 'cities', 'classifications', 'trustyou_score', 'stars' ];
                    let atts = Object.keys( activeFilters );

                    atts.map(function(att, i) {
                        if( ! activeFilters[ att ].includes( hotel.attr('data-' + att) ) ) {
                            activeFilters[ att ].push( hotel.attr('data-' + att) );
                        }
                    });

                });

                Object.keys(activeFilters).map(function(filter, i){
                    if( activeFilters[ filter ].length ) {

                        jQuery('.sqh-hwf-filter[data-type="' + filter + '"]').addClass('invisible');

                        activeFilters[ filter ].map(function(filterVal, i){

                            jQuery('.sqh-hwf-filter[data-type="' + filter + '"][data-value="' + filterVal + '"]').removeClass('invisible').addClass('visible');
                            jQuery('.sqh-hwf-filter[data-type="' + filter + '"][data-value="' + filterVal + '"] span.default').hide();
                            jQuery('.sqh-hwf-filter[data-type="' + filter + '"][data-value="' + filterVal + '"] span.default').after( '<span class="filtered">-> ' + jQuery('.sqh-hwf-hotel[data-' + filter + '="' + filterVal + '"].active').length + '</span>');

                        });

                    }
                });

            }

        }

    }

    meetingRoomsApplyFilters() {

        let visibleHotels = [];

        jQuery('.sqh-hwf-filter').removeClass(['visible', 'invisible']).show();
        jQuery('.sqh-hwf-filter span.filtered').remove();
        jQuery('.sqh-hwf-filter span.default').show();

        let freeSearch        = jQuery('#filters-find-hotels-free-search').val();
        let filtersRegions    = jQuery('#sqh-hwf-filters-regions').find('.sqh-hwf-filter.active');
        let filtersSpace      = jQuery('#sqh-hwf-filters-space').find('.sqh-hwf-filter.active');
        let filtersBanquet    = jQuery('#sqh-hwf-filters-banquet').find('.sqh-hwf-filter.active');
        let filtersConSeat    = jQuery('#sqh-hwf-filters-concert_seating').find('.sqh-hwf-filter.active');
        let filtersParliament = jQuery('#sqh-hwf-filters-parliament').find('.sqh-hwf-filter.active');
        let filtersReception  = jQuery('#sqh-hwf-filters-reception').find('.sqh-hwf-filter.active');
        let filtersUForm      = jQuery('#sqh-hwf-filters-uform').find('.sqh-hwf-filter.active');

        if(
            ! freeSearch &&
            ! filtersRegions.length &&
            ! filtersSpace.length &&
            ! filtersBanquet.length &&
            ! filtersConSeat.length &&
            ! filtersParliament.length &&
            ! filtersReception.length &&
            ! filtersUForm.length
        ) {

            jQuery('.sqh-hwf-hotel').removeClass('active').show();
            jQuery('.sqh-hotel-counter span').text( jQuery('.sqh-hwf-hotel').length );
            visibleHotels = [];

        } else {

            jQuery('.sqh-hwf-hotel').removeClass('active').hide();

            let actFilReg = [];
            let actFilSpa = [];
            let actFilBan = [];
            let actFilCSe = [];
            let actFilPar = [];
            let actFilRec = [];
            let actFilUFo = [];
            let filteredHotels = [];

            if( filtersRegions.length ) {
                filtersRegions.each(function(i, afr) {
                    let value = jQuery(this).data('value');
                    if( ! actFilReg.includes( value ) ) {
                        actFilReg.push( value );
                    }
                });
            }

            if( filtersSpace.length ) {
                filtersSpace.each(function(i, afc){
                    let value = jQuery(this).data('value');
                    if( ! actFilSpa.includes( value ) ) {
                        actFilSpa.push( value );
                    }
                });
            }

            if( filtersBanquet.length ) {
                filtersBanquet.each(function(i, afc){
                    let value = jQuery(this).data('value');
                    if( ! actFilBan.includes( value ) ) {
                        actFilBan.push( value );
                    }
                });
            }

            if( filtersConSeat.length ) {
                filtersConSeat.each(function(i, afc){
                    let value = jQuery(this).data('value');
                    if( ! actFilCSe.includes( value ) ) {
                        actFilCSe.push( value );
                    }
                });
            }

            if( filtersParliament.length ) {
                filtersParliament.each(function(i, afc){
                    let value = jQuery(this).data('value');
                    if( ! actFilPar.includes( value ) ) {
                        actFilPar.push( value );
                    }
                });
            }

            if( filtersReception.length ) {
                filtersReception.each(function(i, afc){
                    let value = jQuery(this).data('value');
                    if( ! actFilRec.includes( value ) ) {
                        actFilRec.push( value );
                    }
                });
            }

            if( filtersUForm.length ) {
                filtersUForm.each(function(i, afc){
                    let value = jQuery(this).data('value');
                    if( ! actFilUFo.includes( value ) ) {
                        actFilUFo.push( value );
                    }
                });
            }

            jQuery('.sqh-hwf-hotel').each(function(i, hotel) {

                let title    = jQuery(this).find('.sqh-hwf-hotel-name').text();
                let hotelReg = jQuery(this).attr('data-regions') ? jQuery(this).attr('data-regions').split('|') : [];
                let hotelSpa = jQuery(this).attr('data-space') ? jQuery(this).attr('data-space').split('|') : [];
                let hotelBan = jQuery(this).attr('data-banquet') ? jQuery(this).attr('data-banquet').split('|') : [];
                let hotelCSe = jQuery(this).attr('data-concert_seating') ? jQuery(this).attr('data-concert_seating').split('|') : [];
                let hotelPar = jQuery(this).attr('data-parliament') ? jQuery(this).attr('data-parliament').split('|') : [];
                let hotelRec = jQuery(this).attr('data-reception') ? jQuery(this).attr('data-reception').split('|') : [];
                let hotelUFo = jQuery(this).attr('data-uform') ? jQuery(this).attr('data-uform').split('|') : [];

                if(
                    ( actFilReg.length == 0 || ( actFilReg.length && hotelReg.some(i => actFilReg.includes(i)) ) ) &&
                    ( actFilSpa.length == 0 || ( actFilSpa.length && hotelSpa.some(i => actFilSpa.includes(i)) ) ) &&
                    ( actFilBan.length == 0 || ( actFilBan.length && hotelBan.some(i => actFilBan.includes(i)) ) ) &&
                    ( actFilCSe.length == 0 || ( actFilCSe.length && hotelCSe.some(i => actFilCSe.includes(i)) ) ) &&
                    ( actFilPar.length == 0 || ( actFilPar.length && hotelPar.some(i => actFilPar.includes(i)) ) ) &&
                    ( actFilRec.length == 0 || ( actFilRec.length && hotelRec.some(i => actFilRec.includes(i)) ) ) &&
                    ( actFilUFo.length == 0 || ( actFilUFo.length && hotelUFo.some(i => actFilUFo.includes(i)) ) ) &&
                    ( ! freeSearch || ( freeSearch && ( title.includes( freeSearch ) || title.toLowerCase().includes( freeSearch ) || location.includes( freeSearch ) ||  location.toLowerCase().includes( freeSearch ) ) ) )
                ) {
                    filteredHotels.push( jQuery(this) );
                }

            });

            if( filteredHotels.length ) {

                filteredHotels.map(function(hotel, i){
                    hotel.addClass('active').show();
                    visibleHotels.push(hotel);
                });

                jQuery('.sqh-hotel-counter span').text( filteredHotels.length );

            }

            if( sqhFrontEnd.ftype == 'dependent' ) {

                let activeFilters = {
                    regions: [],
                    space: [],
                    banquet: [],
                    concert_seating: [],
                    parliament: [],
                    reception: [],
                    uform: []
                };

                visibleHotels.map(function(hotel, i) {

                    let atts = Object.keys( activeFilters );

                    atts.map(function(att, i) {

                        let hotelAtts = hotel.attr('data-'+att);
                        if( hotelAtts ) {
                            hotelAtts = hotelAtts.split('|');
                            hotelAtts.map(function(hotelAtt, i){
                                if( ! activeFilters[ att ].includes( hotelAtt ) ) {
                                    activeFilters[ att ].push( hotelAtt );
                                }
                            });
                        }

                    });

                });

                Object.keys(activeFilters).map(function(filter, i) {

                    if( activeFilters[ filter ].length ) {

                        jQuery('.sqh-hwf-filter[data-type="' + filter + '"]').addClass('invisible');

                        activeFilters[ filter ].map(function(filterVal, i){

                            jQuery('.sqh-hwf-filter[data-type="' + filter + '"][data-value="' + filterVal + '"]').removeClass('invisible').addClass('visible');

                            let filterHotNo = 0;
                            jQuery('.sqh-hwf-hotel.active').each(function(hot, i){
                                if( jQuery(this).attr('data-' + filter) && jQuery(this).attr('data-' + filter).includes( filterVal ) ) {
                                    filterHotNo = filterHotNo + 1;
                                }
                            });

                            jQuery('.sqh-hwf-filter[data-type="' + filter + '"][data-value="' + filterVal + '"] span.default').hide();
                            jQuery('.sqh-hwf-filter[data-type="' + filter + '"][data-value="' + filterVal + '"] span.default').after( '<span class="filtered">-> ' + filterHotNo + '</span>');

                        });

                    }

                });

            }

        }

    }

}
